/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

const THIS_PAGE = `/`;

function ContactForm() {
  const [formData, setFormData] = useState({
    name: ``,
    email: ``,
    message: ``,
  });
  const [statusText, setStatusText] = useState(``);

  const handleChange = (event) => {
    const key = event.target.name;
    const updatedFormValue = event.target.value;
    const newFormData = { ...formData, [key]: updatedFormValue };
    setFormData(newFormData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.target;

    fetch(THIS_PAGE, {
      method: `POST`,
      headers: { 'Content-Type': `application/x-www-form-urlencoded` },
      body: new URLSearchParams({
        'form-name': form.getAttribute(`name`),
        ...formData,
      }).toString(),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);

        setFormData({
          name: ``,
          email: ``,
          message: ``,
        });

        setStatusText(`Thank you!`);
      })
      .catch((error) => setStatusText(`Error: ${error.message}`));
  };

  // FUER AUSBAUEN DES FORMS
  // const validateEmail = (email) =>
  //   String(email)
  //     .toLowerCase()
  //     .match(
  //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  //     );

  return (
    <form
      name="contact-form"
      id="contact-form"
      method="POST"
      onSubmit={(e) => handleSubmit(e)}
      action={THIS_PAGE}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact-form" />
      <div className="mb-2">
        <label className="block" htmlFor="name">
          Name
        </label>
        <input
          className="block w-full p-2 text-black"
          type="text"
          name="name"
          id="name"
          required
          value={formData.name}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="mb-2">
        <label className="block" htmlFor="email">
          Email
        </label>
        <input
          className="block w-full p-2 text-black"
          type="text"
          name="email"
          id="email"
          required
          value={formData.email}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="mb-4">
        <label className="block" htmlFor="message">
          Message
        </label>
        <textarea
          className="block w-full p-2 text-black"
          name="message"
          id="message"
          required
          rows={6}
          value={formData.message}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div>
        <button
          className="px-4 py-2 text-black bg-teal-400 rounded disabled:bg-gray-400"
          type="submit"
          name="submit"
          disabled={!formData.name || !formData.message || !formData.email}
        >
          Send
        </button>
      </div>
      {statusText && (
        <p className="mt-2 text-lg font-bold text-white"> {statusText}</p>
      )}
    </form>
  );
}

export default ContactForm;
