import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFolder } from '@fortawesome/free-solid-svg-icons';
// import { Link } from 'gatsby';
import Title from '@/components/Title';
import LayoutMain from '@/components/Layouts/LayoutMain';

import ContactForm from '@/components/ContactForm/ContactForm';

export default function Contact() {
  return (
    <LayoutMain>
      <Title>Contact</Title>

      <div className="flex flex-wrap">
        <div className="text-white basis-2/3">
          <ContactForm />
        </div>
        <div className="pl-4 basis-1/3" />
      </div>
    </LayoutMain>
  );
}
